<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-6">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="배출시설명"
                    name="envAirMstDischargeName"
                    v-model="data.envAirMstDischargeName">
                  </c-text>
                </div>
                <div class="col-6">
                  <c-text
                    :editable="editable"
                    label="배출시설 관리번호"
                    name="envAirMstDischargeNo"
                    v-model="data.envAirMstDischargeNo">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
                </div>
                <div class="col-3">
                  <c-dept type="edit" label="관리부서" name="deptCd" v-model="data.deptCd" />
                </div>
                <div class="col-3">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="사용여부"
                    name="useFlag"
                    v-model="data.useFlag"
                  />
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="배출시설별 사용연료"
          tableId="grid"
          :columns="grid.columns"
          :data="grid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="300px"
          selection="multiple"
          rowKey="envAirMstFuelId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" v-if="editable && popupParam.envAirMstDischargeId" icon="add" @btnClicked="addrow1" />
              <c-btn label="삭제" v-if="editable && popupParam.envAirMstDischargeId" icon="remove" @btnClicked="removeRow1" />
            </q-btn-group>
          </template>
        </c-table>
      </div> -->
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master03-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envAirMstDischargeId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirMstDischargeId: '',  // 대기배출시설 일련번호
        envAirMstDischargeName: '',  // 대기배출시설명
        envAirMstDischargeNo: '',  // 대기배출시설 관리번호
        deptCd: '',  // 관리부서 코드
        useFlag: 'Y',  // 사용여부
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: transactionConfig.env.air.mst.discharge.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      itemListUrl: '',
      itemInsertUrl: '',
      itemDeleteUrl: '',
      grid: {
        columns: [
          {
            name: 'envAirMstFuelName',
            field: 'envAirMstFuelName',
            label: '연료명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'airFuelUnitName',
            field: 'airFuelUnitName',
            label: '단위',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.mst.discharge.get.url;
      this.saveUrl = transactionConfig.env.air.mst.discharge.insert.url;
      this.itemListUrl = selectConfig.env.air.mst.discharge.fuel.url;
      this.itemInsertUrl = transactionConfig.env.air.mst.discharge.fuel.insert.url;
      this.itemDeleteUrl = transactionConfig.env.air.mst.discharge.fuel.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envAirMstDischargeId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirMstDischargeId: this.popupParam.envAirMstDischargeId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.getItemList();
        },);
      }
    },
    getItemList() {
      if (this.popupParam.envAirMstDischargeId) {
        this.$http.url = this.itemListUrl;
        this.$http.param = {envAirMstDischargeId: this.popupParam.envAirMstDischargeId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveInfo() {
      if (this.popupParam.envAirMstDischargeId) {
        this.saveUrl = transactionConfig.env.air.mst.discharge.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.air.mst.discharge.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envAirMstDischargeId = result.data.envAirMstDischargeId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    addrow1() {
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./airMasterFuelPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup1;
    },
    closePopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { envAirMstFuelId: item.envAirMstFuelId }) === -1) {
            saveData.push({
              envAirMstDischargeId: this.popupParam.envAirMstDischargeId,  // 사업장별 대기 검사항목 일련번호
              envAirMstFuelId: item.envAirMstFuelId,
              airFuelUnitName: item.airFuelUnitName,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
          }
        });
        this.$http.url = this.itemInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getItemList();
        });
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.itemDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>